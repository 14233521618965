<template>
	<ul>
		<li
			v-for="(item, index) in schema"
			:key="index"
			class="w-full m-0 text-left"
		>
			<a
				:id="`h-tag-nav-${item.data.tagId}`"
				:href="`#${item.data.tagId}`"
				:class="[
					{ 'font-semibold text-green-500' : item.data.tagId === activeTagId && item.data.level === 3},
					{ 'font-bold' : item.data.level === 2 }
				]"
				class="block w-full ml-2 text-left"
			>
				{{ item.data.content }}
			</a>
			<HTagNavItem
				v-if="item.children.length > 0"
				:schema="item.children"
				:active-tag-id="activeTagId"
			/>
		</li>
	</ul>
</template>

<script async>
export default {
	name: 'HTagNavItem',
	components: {
		HTagNavItem: () => import('@/components/multiUse/HTagNavItem.vue')
	},
	props: {
		schema: {
			type: Array,
			default: function () {
				return []
			}
		},
		activeTagId: {
			type: String,
			default: ''
		}
	},
	data: function () {
		return {
			observer: ''
		}
	}
}
</script>
